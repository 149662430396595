import Dataman from 'App/Dataman';
import { CAT_NICE_CLASSES } from 'App/utils/Collections';

export async function loadTrademarksSuggestions(companyID) {
  try {
    const response = await Dataman.DB.getTrademarksSuggestions(companyID);
    const result = response.data.map((trademark) => trademark.id);
    return result;
  } catch {
    return null;
  }
}

export async function loadNiceClasses() {
  return Dataman.DB[CAT_NICE_CLASSES].getList({ itemsPerPage: 300 }).then((res) => {
    return res.data.map((el) => ({ text: el.id, value: el.id, _subtitle: el.description }));
  });
}

export function searchOffices(term) {
  return Dataman.DB.trademark.loadOffices({ code: term.toUpperCase() }).then((res) => {
    return res.data.map((el) => ({ text: el.name, value: el.code }));
  });
}

export function loadTrademarks() {
  return Dataman.DB.getTrademarks({
    properties: ['id', 'name'],
    itemsPerPage: 300,
  }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.code }));
  });
}

export function loadTrademarkHolders() {
  return Dataman.DB.trademarkHolders.getList().then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function searchTrademarkHolders(term) {
  return Dataman.DB.trademarkHolders.getList({ name: term }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export default {
  loadTrademarksSuggestions,
  loadNiceClasses,
  searchOffices,
  loadTrademarks,
  searchTrademarkHolders,
  loadTrademarkHolders,
};
