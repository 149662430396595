import { persist, restore, time } from 'App/utils/Helpers';
import { EventDispatcher } from 'App/utils/EventDispatcher';
import { i18n } from 'App/plugins/i18n';
import FilterFactory from './FiltersFactory';

/**
 * @typedef {Object} FilterConf
 * @property {string} category
 * @property {Array<Object>} filters
 * @property {Array<function>} [resources]
 * @property {string} [searchBarProp]
 * @property {string} [useStrictSearch]
 */

/**
 * @class Filter
 *
 * @property {Array<FilterItem>} items
 */
export default class Filter extends EventDispatcher {
  /**
   * @type {boolean}
   */
  #initialized = false;
  /**
   * @type {boolean}
   */
  #initializing = false;
  /**
   * @type {FilterConf}
   */
  #config;
  /**
   * @type {Map}
   */
  #map;
  /**
   * @type {Set}
   */
  #activeItems;
  #naPropertyMap;
  /**
   * @type {boolean}
   */
  #active = false;

  #subCat = null;

  search = '';
  strict = false;

  constructor(config) {
    super();
    this.#config = config;
    this.#map = new Map();
    this.#naPropertyMap = new Map();
    this.#activeItems = new Set();
    this.items = [];
  }

  init = async (force) => {
    if (this.#initializing || (this.#initialized && !force)) {
      return true;
    }
    const factory = new FilterFactory();
    this.#initializing = true;
    const res = await this.loadResources();
    this.items = [...this.#config.filters, ...res].reduce((acc, conf) => {
      if (
        this.#subCat === null ||
        !conf.categories ||
        conf.categories?.length === 0 ||
        conf.categories?.includes(this.#subCat)
      ) {
        try {
          const item = factory.createFilter(conf, this.#subCat);
          if (item.options.naProp) {
            this.#naPropertyMap.set(item.options.naProp, item.id);
          }
          this.#map.set(item.id, item);
          acc.push(item);
        } catch (err) {
          console.error(err);
        }
      }
      return acc;
    }, []);
    this.#initialized = true;
    this.#initializing = false;
    this.dispatchEvent({ type: 'init', target: this });
    return true;
  };

  loadResources = async () => {
    if (this.#config.resources?.length) {
      const srcFetcher = async ({ name, loader }) => {
        if (typeof loader === 'function') {
          const now = time();
          const key = `wims-resources::${this.category}::${name}::${i18n.locale}`;
          const cached = restore(key, null);
          if (cached && cached.expireAt > now && cached.data?.length > 0) {
            return cached.data;
          }
          const resource = await loader();
          persist(key, { data: resource, expireAt: now + 3600 });
          return resource;
        }
        return Promise.resolve([]);
      };
      return Promise.all(this.#config.resources.map(srcFetcher)).then((results) =>
        results.reduce((acc, result) => [...acc, ...result], [])
      );
    }
    return Promise.resolve([]);
  };

  get category() {
    return this.#config.category;
  }

  get initialized() {
    return this.#initialized;
  }

  /**
   * @param {string} id
   * @return FilterItem
   */
  getItem = (id) => {
    return this.#map.get(id);
  };

  activateItem = (id) => {
    const item = this.getItem(id);
    if (item) {
      item.activate();
      this.#activeItems.add(id);
      this.dispatchEvent({
        type: 'item:toggle',
        filter: this,
        state: true,
        item,
      });
      this.dispatchEvent({ type: 'change', filter: this, action: 'activate' });
    }
  };

  deactivateItem = (id) => {
    const item = this.getItem(id);
    if (item) {
      item.deactivate();
      item.resetValue();
      if (item.na && item.useNASearch) {
        item.resetNAValue();
      }
      this.#activeItems.delete(id);
      this.dispatchEvent({
        type: 'item:toggle',
        filter: this,
        state: false,
        item,
      });
      this.dispatchEvent({ type: 'change', filter: this, action: 'remove' });
    }
  };

  isItemActive = (id) => {
    return this.#activeItems.has(id);
  };

  getActiveAmount = () => {
    return this.#activeItems.size;
  };

  getActiveItems = () => {
    return [...this.#activeItems].map((id) => this.getItem(id));
  };

  setItemValue = (id, value) => {
    const item = this.getItem(id);
    if (item) {
      item.value = value;
    }
  };

  resetItemValue = (id) => {
    const item = this.getItem(id);
    if (item) {
      item.resetValue();
    }
  };

  deactivateAll = () => {
    this.items.forEach((item) => {
      item.deactivate();
      this.#activeItems.delete(item.id);
      item.resetValue();
    });
    this.dispatchEvent({ type: 'reset', filter: this });
    this.dispatchEvent({ type: 'change', filter: this });
  };

  activateAll = () => {
    this.items.forEach((item) => {
      item.activate();
      this.#activeItems.add(item.id);
    });
    this.dispatchEvent({ type: 'change', filter: this });
  };

  collectValues = async () => {
    const filters = this.getActiveItems();
    const parentFilters = filters.filter((filter) => !filter.options.parent);
    const childrenWithoutParent = filters.filter((cf) => {
      return cf.options.parent && !parentFilters.map((pf) => pf.id).includes(cf.options.parent);
    });
    let data = {};
    const features = [];
    function getChildPropertyCalculator(collectedParent) {
      const lastItem = Object.keys(collectedParent).at(-1);

      const regex = /\[(\d+)\]/;
      const match = lastItem.match(regex);
      const propertyName = lastItem.replace(/\[\d+\]/, '');
      let lastIndex = 0;
      if (match) {
        lastIndex = parseInt(match[1], 10);
      }
      return (offset) => {
        const propNumber = lastIndex + offset + 1;
        return `${propertyName}[${propNumber}]`;
      };
    }
    [...parentFilters, ...childrenWithoutParent].forEach((filter) => {
      const children = [];
      filters.forEach((f) => {
        if (f.options.parent == filter.id) {
          const collectedChild = f.collectValues();
          children.push(Object.entries(collectedChild).map(([, v]) => v));
        }
      });
      const collectedValues = filter.collectValues(filters);

      children.forEach((values) => {
        const propertyCalculator = getChildPropertyCalculator(collectedValues);
        values.forEach((v, index) => {
          collectedValues[propertyCalculator(index)] = v;
        });
      });
      if (collectedValues.__feature) {
        features.push(collectedValues.__feature);
        delete collectedValues.__feature;
      }
      data = { ...data, ...collectedValues };
    });

    let ungroupedFeatures = [];
    features.forEach((group) => {
      if (Array.isArray(group)) {
        ungroupedFeatures = [...ungroupedFeatures, ...group];
      } else {
        ungroupedFeatures = [...ungroupedFeatures, group];
      }
    });
    data.features = JSON.stringify(ungroupedFeatures);
    return data;
  };

  findItem = (id) => {
    let item = this.getItem(id);
    if (!item) {
      return this.items.find((el) => Object.values(el.strategyIds || {}).includes(id));
    }
    return item;
  };

  applyUserFilter = async (userFilter) => {
    const { category, searchString, conditions } = userFilter;
    const { ...rest } = conditions;
    if (category && category !== this.category) {
      return;
    }
    const isStrict = /^['"].*['"]$/.test(searchString);
    await this.applyFilter(rest, searchString, isStrict);
  };

  applyFilter = async (conditions, searchString, strict = false) => {
    if (searchString) {
      searchString = searchString.replaceAll("'", '').replaceAll('"', '');
    }
    this.search = searchString;
    this.strict = strict;
    this.deactivateAll();
    const { features: serializedFeatures, ...filters } = conditions;
    const filtersMap = {};
    for (const [k, v] of Object.entries(filters || {})) {
      let id = getItemId(k);
      // TODO: fix it
      id = id.replaceAll('[tokenized][or]', '[tokenized]').replaceAll('[tokenized][and]', '[tokenized]');
      if (id in filtersMap) {
        filtersMap[id].values.push(v);
        filtersMap[id].keyValueMap[k] = v;
      } else {
        filtersMap[id] = {
          values: [v],
          keyValueMap: {
            [k]: v,
          },
        };
      }
    }

    const childrenMap = {};
    const childPromises = [];
    for (let filterKey in filtersMap) {
      const filter = this.findItem(filterKey);
      if (!filter) {
        continue;
      }
      if (filter.options.children && filter.options.children.length) {
        filter.options.children.forEach((childID) => {
          const childrenItems = this.items.filter((f) => f.id == childID);
          childrenItems.forEach((child) => {
            const promise = new Promise((res) => {
              child.options.childOptions.childItems().then((result) => {
                const childItemsIDs = result.map((item) => item.value || item.id);
                childrenMap[filter.id] = {
                  childID: child.id,
                  items: childItemsIDs,
                };
                res();
              });
            });
            childPromises.push(promise);
          });
        });
      }
    }
    await Promise.all(childPromises);
    function isChildren([, v], childItems) {
      let result = true;
      v.forEach((item) => {
        result = result && childItems.includes(item);
      });
      return result;
    }
    for (let key in filtersMap) {
      if (!childrenMap[key]) {
        continue;
      }
      const children = Object.entries(filtersMap[key].keyValueMap).filter((entry) =>
        isChildren(entry, childrenMap[key].items)
      );
      if (children.length == 0) {
        continue;
      }
      const parent = Object.entries(filtersMap[key].keyValueMap).filter(
        (entry) => !isChildren(entry, childrenMap[key].items)
      );
      filtersMap[key].keyValueMap = Object.fromEntries(parent);
      filtersMap[key].values = parent.map((entry) => entry[1]);
      const propName = childrenMap[getItemId(children[0][0])].childID;
      const preparedChildren = children.map(([k, v], index) => {
        const propName = childrenMap[getItemId(k)].childID;
        return [`${propName}[${index + 1}]`, v];
      });
      const childrenObject = Object.fromEntries(preparedChildren);
      const filterMapItem = {
        keyValueMap: {
          ...childrenObject,
        },
        values: Object.values(childrenObject),
      };
      filtersMap[propName] = filterMapItem;
    }
    const promises = [];
    let features = [];
    if (serializedFeatures) {
      try {
        features = JSON.parse(serializedFeatures);
      } catch (err) {
        features = [];
      }
    }

    const featuresIds = new Set();
    features.forEach((f) => featuresIds.add(Object.keys(f)[0]));

    const groupedFeatures = [];
    const featuresIdsIterator = featuresIds.values();

    [...featuresIdsIterator].forEach((id) => {
      const group = features.filter((f) => id in f);
      groupedFeatures.push(group);
    });

    features = groupedFeatures;

    for (let filterKey in filtersMap) {
      let filter = this.findItem(filterKey);
      if (filterKey == 'exists') {
        const filterID = this.#naPropertyMap.get(Object.keys(filtersMap[filterKey].keyValueMap)[0]);
        filter = this.findItem(filterID);
      }
      if (!filter) {
        continue;
      }
      const promise = new Promise((res, rej) => {
        filter
          .apply(filtersMap[filterKey].values, filtersMap[filterKey].keyValueMap)
          .then(() => {
            filter.activate();
            this.#activeItems.add(filter.id);
            res();
          })
          .catch((err) => rej(err));
      });
      promises.push(promise);
    }
    for (let i in features) {
      let values = features[i];
      if (!Array.isArray(values)) {
        values = [values];
      }
      if (!values.length) continue;
      const valueKeys = Object.keys(values[0]);
      const key = valueKeys[0];
      const not = valueKeys[1];
      let filterKey;
      if (values[0][key] && values[0][not]) {
        filterKey = `not_feature${key}`;
      } else {
        filterKey = `feature${key}`;
      }
      let filter = this.findItem(filterKey);
      if (!filter) {
        continue;
      }
      const promise = new Promise((res, rej) => {
        filter
          .apply([...values.map((v) => v[key])], {}, values)
          .then(() => {
            filter.activate();
            this.#activeItems.add(filter.id);
            res();
          })
          .catch((err) => rej(err));
      });
      promises.push(promise);
    }
    try {
      await Promise.all(promises);
      this.dispatchEvent({ type: 'change', filter: this, apply: true });
    } catch (error) {
      console.error(error);
    }
  };

  get active() {
    return this.#active;
  }

  set active(state) {
    this.#active = !!state;
    this.dispatchEvent({ type: 'activate', filter: this, state: this.#active });
  }

  get searchBarProp() {
    return this.#config.searchBarProp || 'name';
  }

  get useStrictSearch() {
    return this.#config.useStrictSearch;
  }

  get subCat() {
    return this.#subCat;
  }

  set subCat(value) {
    this.#subCat = value;
  }
}

function getItemId(key) {
  if (key.includes('[tokenized]')) {
    return key;
  }
  if (/^feature?\d+$/.test(key)) {
    return key;
  }
  // -_- just try to fix this... (never)
  if (key === 'exists[files]') {
    return key;
  }
  if (/^\d+$/.test(key)) {
    return `feature${key}`;
  }
  if (/^.+\[.*]$/i.test(key)) {
    return key.replace(/\[.*]$/gi, '');
  }
  return key;
}
