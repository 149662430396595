import { FT_LIST, FT_SINGLE } from '../FilterTypes';
import { CAT_TYPES } from 'App/utils/Collections';
import { loadModules } from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_TYPES,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'modules',
      label: 'Module',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadModules,
    },
  ],
};
