import types from './FilterTypes';
import filters from './filterTypes/index.js';

class FilterFactory {
  constructor() {
    this.filterConstructors = {
      [types.FT_SINGLE]: filters.FilterSingle,
      [types.FT_LIST]: filters.FilterList,
      [types.FT_SUGGESTION]: filters.FilterSuggestion,
      [types.FT_AUTOCOMPLETE]: filters.FilterAutocomplete,
      [types.FT_RANGE]: filters.FilterRange,
      [types.FT_DATE]: filters.FilterDate,
      [types.FT_CHECKBOX]: filters.FilterCheckbox,
      [types.FT_CONDITION_GROUP]: filters.FilterConditiongroup,
      [types.FT_MULTI_STRATEGY]: filters.FilterMultiStrategy,
    };
  }

  createFilter(conf, ...args) {
    const FilterConstructor = this.filterConstructors[conf.type];

    if (FilterConstructor) {
      return new FilterConstructor(conf, ...args);
    } else {
      throw new Error('Unknown filter type: ' + conf.type);
    }
  }
}

export default FilterFactory;
