import {
  CAT_PRODUCTS,
  CAT_COMPANIES,
  CAT_COMPONENTS,
  CAT_PATENTS,
  CAT_TRADEMARKS,
  CAT_CONTACTS,
  CAT_BRANDS,
  CAT_EVENTS,
  CAT_EVENT_TYPES,
  CAT_EVENT_THEMES,
  CAT_RESEARCHES,
  CAT_PRODUCT_ORDERS,
} from '@/app/utils/Collections.js';
export default {
  [CAT_PRODUCTS]: 1,
  [CAT_COMPANIES]: 2,
  [CAT_COMPONENTS]: 3,
  [CAT_PATENTS]: 4,
  [CAT_TRADEMARKS]: 5,
  [CAT_CONTACTS]: 6,
  [CAT_BRANDS]: 7,
  [CAT_EVENTS]: 8,
  [CAT_RESEARCHES]: 10,
  [CAT_EVENT_THEMES]: 11,
  [CAT_EVENT_TYPES]: 12,
  [CAT_PRODUCT_ORDERS]: 13,
};
