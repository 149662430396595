import Dataman from 'App/Dataman';
import { sortAsc } from 'App/utils/Helpers';
import { CAT_PRODUCTS } from 'App/utils/Collections';
import ItemMaster from 'App/ItemMaster';
import { Trans } from 'App/plugins/Translation';

export function statusItemsLoader() {
  return Dataman.DB.getProductOrderStatuses({
    useInProductOrder: true,
    'translations.locale': Trans.currentLanguage,
    'order[translations.name]': 'asc',
  }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function searchStatusItems(term) {
  return Dataman.DB.getProductOrderStatuses({
    'translations.name': term,
    'translations.locale': Trans.currentLanguage,
    useInProductOrder: true,
    'order[translations.name]': 'asc',
  }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function loadCourierDeliveryServices() {
  return Dataman.DB.getCourierDeliveryServises({ 'order[name]': 'asc' }).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export function searchCourierDeliveryServices(term) {
  const filter = { name: term, 'order[name]': 'asc' };
  return Dataman.DB.getCourierDeliveryServises(filter).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.name, value: el.id }));
  });
}

export async function searchOrderProduct(term) {
  const filter = { name: term, properties: ['id', 'name', 'category'] };
  return ItemMaster.getList({ filter, category: CAT_PRODUCTS }).then((data) => {
    return data.map((el) => ({ text: el.name, value: el.name }));
  });
}

export function loadOrderProducts() {
  const filter = { itemsPerPage: 100, properties: ['id', 'name', 'category'] };
  return Dataman.DB.getProducts(filter).then((res) => {
    return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.name }));
  });
}

export function loadCustomers() {
  const filter = { itemsPerPage: 100, 'order[fullName]': 'asc', properties: ['id', 'fullName'] };
  return Dataman.DB.customers.getList(filter).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.fullName, value: el.id }));
  });
}

export async function searchCustomers(term) {
  const filter = { name: term, 'order[fullName]': 'asc', properties: ['id', 'fullName'] };
  return Dataman.DB.customers.getList(filter).then((res) => {
    return res.data['hydra:member'].map((el) => ({ text: el.fullName, value: el.id }));
  });
}

export function loadProjects() {
  const filter = { itemsPerPage: 100, properties: ['id', 'name'] };
  return Dataman.DB.projects.getList(filter).then((res) => {
    return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
  });
}

export async function searchProjects(term) {
  const filter = { name: term, properties: ['id', 'name'] };
  return Dataman.DB.projects.getList(filter).then((res) => {
    return res.data['hydra:member'].sort(() => sortAsc('name')).map((el) => ({ text: el.name, value: el.id }));
  });
}
